import BaseService from "./base.service";

class DdfgroupService {
    ddfgroups(page = null, search = null) {
        let params = [];
        if(search) {
            params.push({'key':'q', 'value':search});
        }
        return BaseService.apiGetRequest('ddfgroup', page, params);
    }
    get(id) {
        return BaseService.apiGetRequest('ddfgroup/'+id);
    }
    delete(ddfgroup) {
        return BaseService.apiPostRequest('ddfgroup/delete/', ddfgroup);
    }
    add(ddfgroup) {
        return BaseService.apiPostRequest('ddfgroup/add', ddfgroup);
    }
    update(id, ddfgroup) {
        return BaseService.apiPostRequest('ddfgroup/'+id, ddfgroup);
    }
    search(page=null) {
        return BaseService.apiGetRequest('ddfgroup/search', page);
    }

}

export default new DdfgroupService();
